import {Controller} from '@hotwired/stimulus'
import {Modal} from 'bootstrap'

export default class extends Controller {
    static values = {
        size: String, // sm, lg, xl
        backdrop: String, // true, false or 'static'
        keyboard: Boolean,
        focus: Boolean,
        url: String
    }

    openModal(event) {
        event.preventDefault()
        event.stopPropagation()

        this._modalHolder = document.getElementById('modalHolder')
        let url = this.hasUrlValue ? new URL(this.urlValue) : new URL(this.element.href)
        if (this.hasSizeValue) url.search = new URLSearchParams({size: this.getSizeValue}).toString()

        fetch(url.toString(), {
            credentials: 'same-origin',
            headers: {'X-CSRF-Token': `${document.head.querySelector('meta[name="csrf-token"]').content}`}
        }).then(response => {
            if (!response.ok) throw new Error('Network response was not ok')
            return response.text()
        }).then(text => {
            if (this._domParserSupported()) {
                const parser = new DOMParser()
                let doc = parser.parseFromString(text, 'text/html')
                while (this._modalHolder.firstChild) this._modalHolder.removeChild(this._modalHolder.firstChild)
                this._modalHolder.appendChild(doc.querySelector('body').firstChild)
            } else {
                this._modalHolder.innerHTML = text
            }
        }).then(() => {
            const modal = document.getElementById('modal')
            const modalDialog = new Modal(modal, {
                backdrop: this.getBackdropValue,
                keyboard: this.getKeyboardValue,
                focus: this.getFocusValue
            })
            modalDialog.show()
            modal.addEventListener('shown.bs.modal', (_event) => {
                let focusElement = this._modalHolder.querySelector('[autofocus]')
                if (focusElement) focusElement.focus()
            })
        }).then(() =>
            this._loadScripts()
        ).catch(error =>
            console.log(error)
        )
    }

    _domParserSupported() {
        if (!window.DOMParser) return false
        let parser = new DOMParser()
        try {
            parser.parseFromString('x', 'text/html')
        } catch (_error) {
            return false
        }
        return true
    }

    _loadScripts() {
        this._modalHolder.querySelectorAll('script').forEach((content) => {
            const script = document.createElement('script')
            script.innerHTML = content.innerHTML
            document.head.appendChild(script).parentNode.removeChild(script)
        })
    }

    get getSizeValue() {
        return this.sizeValue || ''
    }

    get getBackdropValue() {
        return this.hasBackdropValue ? this.backdropValue : true
    }

    get getKeyboardValue() {
        return this.hasKeyboardValue ? this.keyboardValue : true
    }

    get getFocusValue() {
        return this.hasFocusValue ? this.focusValue : true
    }
}
