import {Controller} from '@hotwired/stimulus'

export default class extends Controller {
    connect() {
        this._submitFilterForm()
    }

    typing() {
        this._clearTimeout()
        this._setTimeout()
    }

    _setTimeout() {
        this._filterTimeout = setTimeout(function () {
            this._submitFilterForm()
        }.bind(this), 500);
    }

    _clearTimeout() {
        if (this._filterTimeout) clearTimeout(this._filterTimeout);
    }

    _submitFilterForm() {
        document.querySelector('#filter_table_wrapper tbody').innerHTML = '<tr><td colspan="12"><div class="text-center py3"><svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="spinner" class="svg-inline--fa fa-spinner fa-spin fa-w-16 fa-2x" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"></path></svg></div></td></tr>';
        Rails.fire(document.getElementById('filter_table_form'), 'submit');
    }
}
