import {Controller} from '@hotwired/stimulus'
// import {Modal} from 'bootstrap'

export default class extends Controller {
    initialize() {
        this._handlePostSuccess = this._handlePostSuccess.bind(this)
        this._handlePostError = this._handlePostError.bind(this)
    }

    connect() {
        this._installAjaxSuccessListener()
        this._installAjaxErrorListener()
    }

    disconnect() {
        this._uninstallAjaxSuccessListener()
        this._uninstallAjaxErrorListener()
    }

    _installAjaxSuccessListener() {
        this.element.addEventListener('ajax:success', this._handlePostSuccess)
    }

    _uninstallAjaxSuccessListener() {
        this.element.removeEventListener('ajax:success', this._handlePostSuccess)
    }

    _installAjaxErrorListener() {
        this.element.addEventListener('ajax:error', this._handlePostError)
    }

    _uninstallAjaxErrorListener() {
        this.element.removeEventListener('ajax:error', this._handlePostError)
    }

    _handlePostSuccess(event) {
        let modal = document.getElementById('modal')
        // if (modal && !event.target.hasAttribute('data-do-not-close-modal-on-submit')) Modal.getInstance(modal).hide()
    }

    _handlePostError(event) {
        let [data, status, xhr] = event.detail;
        console.log(status)
        if (data.responseText) console.log(data.responseText)
        console.log(data)
    }
}
